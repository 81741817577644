import React from "react"
import styled from "styled-components"
import ServiceCard from './serviceCard'
import { faCar, faFan, faSlidersH, faTasks, faTrophy, faWrench } from "@fortawesome/free-solid-svg-icons"

const ServicesWrapper = styled.div`
    padding: 2rem 1.5rem;

    h2 {
        color: #2196f3;
    }
`
const ServicesGrid = styled.div`
    display: grid;
    grid-auto-rows: auto;
    grid-gap: 1.5rem;

    @media screen and (min-width: 690px) {
        grid-template-columns repeat(2, 1fr);
    }
    @media screen and (min-width: 960px) {
        grid-template-columns repeat(3, 1fr);
    }
`
const Services = () => (
  <ServicesWrapper>
    <h2>Services We Offer</h2>
    <ServicesGrid>
        <ServiceCard
            icon={faTasks}
            theme='dark'
            title='MOT Testing'
            text='Fully approved testing station capable of processing the testing of Class 4 vehicles.'
        />
        <ServiceCard
            icon={faWrench}
            title='Services'
            text='We are equipped to service any make and model and fully insured for all vehicles. We can also reset service interval warning systems.'
        />
        <ServiceCard
            icon={faFan}
            theme='dark'
            title='Air Conditioning'
            text='Both regassing and servicing options available.'
        />
        <ServiceCard
            icon={faSlidersH}
            title='Diagnostics'
            text='Using the latest Bosch systems analyser for testing modern engines, ABS, airbag and traction control management systems.'
        />
        <ServiceCard
            icon={faCar}
            theme='dark'
            title='Fleets Welcome'
            text='With our multi bay premises we can accommodate the needs of your fleet.'
        />
        <ServiceCard
            icon={faTrophy}
            title='Tuning'
            text='We have the knowledge and expertise to perform repairs and tuning to both sports and classic cars.'
        />
    </ServicesGrid>
  </ServicesWrapper>
)

export default Services
