import React from "react"
import styled from "styled-components"

const AboutUsWrapper = styled.div`
    background-color: #1769aa;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    padding: 2rem;

    h2, p {
        color: #fff;
    }
`

const AboutUs = () => (
    <AboutUsWrapper>
        <h2>A Little About Us</h2>
        <p>Established in the mid 1970's by Trevor Tye the aim has always been to provide maximum customer satisfaction and to treat people how you would expect to be treated yourself.</p>
        <p>These old fashioned values are still maintained today by Nigel Tye & his team in modern fully equipped premises.</p>
        <p>We are more than happy to discuss your requirements with you, we can maintain fleets or privately owned vehicles to a high standard even if still covered by your manufacturers warranty.</p>
    </AboutUsWrapper>
)

export default AboutUs
