import React from 'react'
import styled from 'styled-components'

const MapWrapper = styled.div`
    height: 30vh;
    width: 100vw;

    iframe {
        border: none;
    }
`

const TyetuneMap = () => {

    return (
        <MapWrapper>
            <iframe
                width='100%'
                height='100%'
                id='mapcanvas'
                src='https://maps.google.com/maps?q=Tyetune%20auto%20services&amp;z=12&amp;output=embed'
                scrolling='no'
                title="mapWindow"
            >
                <div>
                    <div id='gmap_canvas' />
                </div>
            </iframe>
        </MapWrapper>
    )
}

export default TyetuneMap;

