import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from 'gatsby-image'

const BillboardImage = styled(Img)`
    max-height: 50vh;
`

const Billboard = () => {
    const data = useStaticQuery(graphql`
        query BillboardImage {
            file(relativePath: {
                regex: "/TyetuneAutoServices/"
            }) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <BillboardImage fluid={data.file.childImageSharp.fluid} />
    )
}

export default Billboard
