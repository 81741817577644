import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutUs from '../components/about'
import Billboard from '../components/billboard'
import Contactbar from '../components/contactbar'
import Services from '../components/services'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Billboard />
    <AboutUs />
    <Services />
    <Contactbar />
  </Layout>
)

export default IndexPage
