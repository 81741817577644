import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Card = styled.div`
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    min-height: 12rem;
    padding: 1.5rem 0;
`
const CardTitle = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem 1rem;
    h3 {
        font-size: 1rem;
        margin: 0;
    }
`
const CardBody = styled.p`
    margin: 0;
    padding: 0 1rem;
`

const ServiceCard = ({icon, text, theme, title}) => {
    const textColor = theme === 'dark' ? { color: '#fff' } : { color: '#4dabf5' };
    const background = theme === 'dark' ? { 'backgroundColor': '#4dabf5' } : { 'backgroundColor': '#fff' };
    return (
        <Card style={background}>
            <CardTitle style={textColor}>
                <FontAwesomeIcon icon={icon} size="2x" style={textColor} />
                <h3>{title}</h3>
            </CardTitle>
            <CardBody style={textColor}>
                {text}
            </CardBody>
        </Card>
    );
}

export default ServiceCard
