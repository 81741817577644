import React from "react"
import styled from "styled-components"
import { faAt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import Cta from './cta'
import TyetuneMap from './map'

const ContactbarWrapper = styled.div`
    background-color: #2196f3;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    padding: 2rem;

    h2 {
        color: #fff;
    }
`
const ContactCtaWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

const Contactbar = () => (
  <>
    <ContactbarWrapper>
    <h2>Book an Appointment</h2>
    <ContactCtaWrapper>
        <Cta
            href="tel:+44 1536 712398"
            icon={faPhoneAlt}
            text="Call Us"
        />
        <Cta
            href="mailto:tyetune@yahoo.co.uk"
            icon={faAt}
            target="_blank"
            text="Email Us"
        />
    </ContactCtaWrapper>
    </ContactbarWrapper>
    <TyetuneMap />
  </>
)

export default Contactbar
